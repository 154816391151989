<template>
  <div>
    <Header></Header>
    <div class="banner">
      <img :src="`${$url}/banner/4.png`" alt="">
      <div class="all-title-ani">医药新零售</div>
      <div class="all-title-ani all-ani-delay" style="width: 8rem;">通过线上线下及公域私域相融合，提供导购数字化、营销数字化、会员数字化等赋能工具，帮助医药门店实现全时全域经营，多位一体数字化构建可持续智慧增长。</div>
    </div>
    <div class="details">
      <div>{{text}}</div>
      <img v-if="id == 4" :src="`https://static.drlianzhuren.com/HairTransplant/CloudNumber/details/4.jpg`" alt="">
      <img v-else :src="`https://static.drlianzhuren.com/HairTransplant/CloudNumber/details/${id}.png`" alt="">
    </div>
    <Footer></Footer>
  </div>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      id: 1,
      text: '头皮毛发检测仪（居家款）'
    }
  },
  mounted() {
    const id = this.$route.query.id
    const text = this.$route.query.text
    this.id = id
    this.text = text
  }
}
</script>

<style lang="scss">
.details {
  text-align: center;
  background: #ffffff;
  overflow: hidden;
  div {
    font-size: 34px;
    font-weight: 400;
    color: #181818;
    margin-top: 80px;
    margin-bottom: 48px;
  }
  img {
    width: 702px;
    margin-bottom: 32px;
  }
}
</style>
